$midnight: #01426a;
$marine: #083250;
$atlas: #2774ae;
$white: #ffffff;
$mist: #c8c9c7;
$shark: #6e7783;
$orca: #222222;
$misto: #f5f5f5;
$red: #e3093f;
$lorange: #faf2eb;
$dullorange:#d47f2f;
$blue-link: #0074c8;
$blue-link-focus: #176cff;
$alaska-green: #48850B;
$soft-gray: #656565;
$black: #000000;
$error-color: #cb391f;
$error-text: #994442;
$as-white: #fff;
$medium-grey: #c8c9c7;
$disabled: #f7f6f6;

// Colors
$black: #212223;
$dark-black: #222222;
$as-dark-blue: #01426a;
$as-medium-blue: #2774ae;
$active-blue: #126fe0;
$beach-blue: #48a9c5;
$darkest-blue: #023249;
$fill-blue: #6dd7f5;
$blue-link: #0074c8;
$blue-link-focus: #176cff;
$dark-blue: #0b334f;
$marine: #083250;
$light-blue: #60cae8;
$selected-blue: #e9f3fb;
$as-white: #fff;
$off-white: #e9e9e9;
$active-tab-grey: #495057;
$as-border-grey: #ccc;
$form-field-grey: #656565;
$header-grey: #eeeeee;
$incognito-grey: #8ba6c1;
$light-grey: #f5f5f5;
$medium-grey: #c8c9c7;
$medium-dark-grey: #9d9d9e;
$radio-button-border: #979797;
$button-bg-color: #48850b;
$button-focus-bg-color: #3b6d09;
$success-background-green: #b3d57d;
$label-color: #3c3b3f;
$error-background: #fff7f7;
$error-color: #cb391f;
$error-text: #994442;
$header-label-color: #3c3b3f;
$wheat: #f9e595;
$blocked-orange: #d47f2f;
$million-miler: #7b7b7b;
$icon-grey: #a8a8a8;
$light-grey-1: #fbfbfb;
$light-grey-2: #f7f7f7;
$navy: #072234;
$disabled: #f7f6f6;

// Breakpoints
$mobile: 768px;
$small-desktop: 1024px;
$medium-desktop: 1280px;
$large-desktop: 1440px;
$extra-large-desktop: 1600px;



$button-min-width: 104px;
$button-height: 44px;

$standard-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$regular-size-text: 14px;