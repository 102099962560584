/* You can add global styles to this file, and also import other style files */
@import 'global-variables';

$as-dark-blue: #01426a;

html, body { height: 100%; }
body { margin: 0; font-family: $standard-font; }

.app-title {
    letter-spacing: 1.1px;
    text-transform: uppercase;
    color: $midnight;
}

.button-primary {
    min-width: $button-min-width;
    min-height: $button-height;
    border: solid 2px $blue-link;
    background-color: $blue-link;
    color: $white;
    font-weight: bold;
    text-transform: uppercase;
    //letter-spacing: 1.25px;
    border-radius: 4px;
  
    &:hover {
      border-color: $blue-link-focus;
      background-color: $blue-link-focus;
      text-decoration: underline;
    }
  
    &:active {
      border-color: $atlas;
      background-color: $atlas;
      text-decoration: underline;
    }
  
    &:disabled {
      border: solid 2px $mist;
      background-color: $mist;
      text-decoration: none;
    }
}

.channel-container {
    border: solid 1px $medium-grey;
    padding-top: 10px;
    margin: 16px 16px 0px 16px;
    min-height: 150px;
    max-width: 736px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: white;
}

.message-container {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 25px;
}

.message-body {
    color: $black;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.38px;
    line-height: 25px;
    padding-left: 10px;
    padding-top: 4px;
    word-break: break-word;
    white-space: pre-line;
}

.message-pill {
    height: 22px;
    background: rgb(107, 183, 251);
    border-radius: 12px;
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.24px;
    line-height: 22px;
    align-content: center;
    display: inline-block;
    padding-inline-start: 12px;
    padding-inline-end: 12px;
}

.message-time {
  color: rgba(0,0,0,0.5);
  font-size: 12px;
  margin-left: 8px;
}
.message-no-messages {
  color: $medium-grey;
  font-size: 17px;
  padding-left: 10px;
}

.link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: none;
  }

  &:disabled {
    text-decoration: none;
  }
}

section {
  display: grid;
  grid-template-columns: 42%; /* set column sizes here */
  grid-template-rows: auto auto; /* we want two rows */

  grid-gap: 4px; /* how far between cells? */
  grid-auto-flow: column; /* fill in by column, not row */
}
